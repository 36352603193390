import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, Paper, Divider, FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CustomTable from '../../components/table/SalesTable';
import Addcategory from './VerifyOpen';
import { useDispatch, useSelector } from "react-redux";
import { getcategory, getclass } from "../../slices/class";
import DeleteIcon from "@mui/icons-material/Delete";
import { getcombo, getcombobyid } from '../../slices/combo';
import { getstudent } from '../../slices/student';
import { getteacher } from '../../slices/teacher';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getproduct } from '../../slices/product';
import VerifyOpen from './VerifyOpen';
import { getsales } from '../../slices/sales';
import SearchButton from '../../components/search/Search';

const columns = [
  { id: 'name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'class_name', label: 'Class Name', minWidth: 150, align:'start' },
  { id: 'section', label: 'Section', minWidth: 150, align:'start' },
  { id: 'totalAmount', label: 'Total Amount', minWidth: 150, align:'start' },
  { id: 'status', label: 'Status', minWidth: 150, align:'start' },
];

const rows = [
  {sno: '01', class: 'LKG', section: 'A, B, C, D'},
];

const Sales = ({setLoader}) => {

  const [status, setStatus] = React.useState('All');

  const [filteredData, setFilteredData] = React.useState();

  const [usertype, setUsertype] = React.useState('Student');

  const [sections, setSections] = React.useState([]);

  const [option, setOptions] = React.useState([]);

  const [items, setItems] = useState([]);

  const [bookstatus, setBookStatus] = useState([]);

  const [comboitems, setComboItems] = useState([]);

  const {classes: classData} = useSelector((state) => state.class);

  const {students: studentData} = useSelector((state) => state.student);

  const {teachers: teacherData} = useSelector((state) => state.teacher);

  const {sales: billingData} = useSelector((state) => state.sales);

   const salesData = billingData?.filter(ab => !ab.pastOrder);

  const initialState = {
    user_type:"",
    class_id:"",
    class_name:"",
    section:"",
    name:"",
    phone:"",
    address:"",
    combo_name:"",
    combo_id:"",
    payment_method:"",
    itemData:[items],
    totalAmount:"",
  }

  const [formdata, setFormdata] = React.useState(initialState);

React.useEffect(()=>{
  if(formdata?.class_id){
    setFormdata({...formdata, class_name: (classData?.filter(ab => ab._id === formdata?.class_id)[0]?.name) })
    setSections((classData?.filter(ab => ab._id === formdata?.class_id)[0]?.section))
  }
}, [formdata?.class_id])

React.useEffect(()=>{
  if(usertype === 'Student'){
    if(formdata?.class_id )
    setOptions((studentData?.filter(ab => (ab.class_id === formdata?.class_id) && (ab.section === formdata?.section))))
  }
  if(usertype === 'Teacher'){
    if(formdata?.class_id )
    setOptions((teacherData?.filter(ab => (ab.class_id === formdata?.class_id))))
  }
}, [formdata?.class_id, formdata?.section])

console.log(option);

const handleFieldChange = (name, product_name, event) => {
  const newValue = event.target.value;
  console.log(name, product_name, event);
  setItems(items.map(item1=>{
          if (item1.name === product_name ) {
            return { ...item1, [name]: newValue };
          }
          return item1;
        })
      )
}

React.useEffect(()=>{
  setLoader(true);
  dispatch(getclass())
  dispatch(getstudent())
  dispatch(getteacher())
  dispatch(getsales())
  .then(()=>{
    setLoader(false);
  })
  .catch(()=>{
    setLoader(false);
  })
},[])

const handleValue = (e, name) => {
  setFormdata({
    ...formdata,
    [name]: e.target.value,
  });
}

  const handleChange = (event) => {
    setUsertype(event.target.value);
  };

  const {combos: combosData} = useSelector((state) => state.combo);

  const {products: productData} = useSelector((state) => state.product);

  const {comboPrice: combosFinalData} = useSelector((state) => state.combo);

  useEffect(()=>{

    if(combosFinalData){
      setComboItems(combosFinalData)
    }

  }, [combosFinalData])


  console.log(formdata);

  const dispatch = useDispatch();

  const handleStudentChange = (event, newValue) => {
    const address = newValue ? newValue.address : null;
    const phone = newValue ? newValue.phone : null;
    const name = newValue ? newValue.label : null;
    setFormdata({ ...formdata, name: name, phone: phone, address: address})
  };

  const handleComboChange = (event, newValue) => {
    const id = newValue ? newValue.id : null;
    const name = newValue ? newValue.label : null;
    setFormdata({ ...formdata, combo_name: name, combo_id: id})
    setLoader(true)
    dispatch(getcombobyid(id))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  const handleProductChange = (event, newValue) => {
    setItems([...items, {name: newValue.label, qty: newValue.qty, total_price: newValue.total_price, _id: newValue._id}])
  };

  const handleItemDelete = (item_id) => {
      const filteredItem = items.filter (ca => ca._id !== item_id);
      setItems(filteredItem);
  }

  const handleComboItemDelete = () =>{
    setComboItems([]);
    setFormdata({...formdata, combo_name: "", combo_id: ""})
  }

  const subTotal = items?.map((obj) => Number(obj.total_price * obj.qty))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  console.log(bookstatus);

  const comboTotal = comboitems?.map((obj) => Number(obj.total_price * obj.qty))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
    if(event.target.value === 'All'){
      setFilteredData(salesData);
    }
    if(event.target.value === 'Pending'){
      const filtered = salesData.filter(obj =>{return obj.status === 'Pending'});
      setFilteredData(filtered);
    }
    if(event.target.value === 'Completed'){
      const filtered = salesData.filter(obj =>{return obj.status === 'Completed'});
      setFilteredData(filtered);
    }
  };

  const handleSearch = (query) => {
    const filtered = salesData.filter(item => item.name.includes(query));
    setFilteredData(filtered);
  };

  React.useEffect(()=>{
    setFilteredData(salesData);
  }, [billingData])

  useEffect(()=>{
    setLoader(true)
      dispatch(getcombo())
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2}}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
           <Typography variant='h6' sx={{fontWeight:'bold'}} className='headline'>  Manage Sales</Typography>
           <Box sx={{mt: 3, display:'flex'}}>
            <SearchButton handleSearch={handleSearch} placeholder="Search User"/>
            <FormControl size='small' sx={{ width: 300 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
        </Select>
      </FormControl>
           </Box>
           <Box sx={{mt: 3}}>
           <CustomTable setLoader={setLoader} columns={columns} rows={filteredData ? filteredData : []}/>
           </Box>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 0}}>
          <Typography variant='h6' sx={{fontWeight:'bold'}} className='headline'>Billing</Typography>
          <FormControl sx={{my: 2, mt: 3}} fullWidth>
        <InputLabel id="demo-simple-select-label">User Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={usertype}
          label="User Type"
          onChange={handleChange}
        >
          <MenuItem value="Student">Student</MenuItem>
          <MenuItem value="Teacher">Teacher</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel required id="demo-simple-select-label">Class</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Class"
    value={formdata?.class_id}
    onChange={(e)=>handleValue(e, 'class_id')}
  >
  {classData?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va._id}>{va.name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
{usertype === "Student" && 
                 <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel required id="demo-simple-select-label">Section</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Section"
    value={formdata?.section}
    onChange={(e)=>handleValue(e, 'section')}
  >
  {sections?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va}>{va}</MenuItem>
    )
  })}
  </Select>
</FormControl>
}
<Autocomplete sx={{marginBottom: 2}}
          fullWidth
      disablePortal
      id="combo-box-demo"
      options={option.map(gh => ({phone: gh.phone, label: gh.name, address: gh.address }))}
      // getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.id === value.id}
      value={formdata?.name || null}
      onChange={(event, newValue) => handleStudentChange(event, newValue)}
      renderInput={(params) => <TextField {...params} label="Select User"
      />}
    />
     <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Phone" variant="outlined" 
                  value={formdata?.phone}
                  onChange={(e)=> setFormdata({...formdata, phone: e.target.value})}
         />
          <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Address" variant="outlined" 
                  value={formdata?.address}
                  onChange={(e)=> setFormdata({...formdata, address: e.target.value})}
         />
         <Autocomplete sx={{marginBottom: 2}}
          fullWidth
      disablePortal
      id="combo-box-demo"
      options={combosData.map(gh => ({ label: gh.name, id: gh._id}))}
      // getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.id === value.id}
      value={formdata?.combo_name || null}
      onChange={(event, newValue) => handleComboChange(event, newValue)}
      renderInput={(params) => <TextField {...params} label="Select Combo"
      />}
    />
             <Autocomplete sx={{marginBottom: 2}}
          fullWidth
      disablePortal
      id="combo-box-demo"
      options={productData.map(gh => ({ label: gh.name, qty: 1, total_price: gh.total_price, _id: gh._id}))}
      // getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.id === value.id}
      value={null}
      onChange={(event, newValue) => handleProductChange(event, newValue)}
      renderInput={(params) => <TextField {...params} label="Select Invidual Product"
      />}
    />
    <FormControl sx={{mb: 2}} fullWidth>
        <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formdata?.payment_method}
          label="Payment Mthod"
          onChange={(e)=> setFormdata({...formdata, payment_method: e.target.value})}
        >
          <MenuItem value="Cash">Cash</MenuItem>
          <MenuItem value="Card">Card</MenuItem>
          <MenuItem value="UPI">UPI</MenuItem>
        </Select>
      </FormControl>
                <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>Item</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Qty</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Price</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {formdata?.combo_name !== "" ? 
        <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {formdata?.combo_name}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
              1
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {comboTotal}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                  <HighlightOffIcon onClick={() => handleComboItemDelete()} sx={{color:'red'}}/>
              </TableCell>
            </TableRow>
            :
            null
        }
        { items.map((va, index) => {
          return(
            <TableRow key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {va?.name}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
              <TextField sx={{marginBottom: 2}} id="outlined-basic" variant="outlined" 
                  value={va?.qty}
                  onChange={(e)=> handleFieldChange('qty', va.name, e)}
         />           
              </TableCell>
              <TableCell align="center" component="th" scope="row">
              {va?.qty * va?.total_price}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                  <HighlightOffIcon onClick={() => handleItemDelete(va?._id)} sx={{color:'red'}}/>
              </TableCell>
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
      <Divider sx={{my: 2}}/>
      <Box sx={{display:'flex', justifyContent:'end', gap: 3, alignItems:'end', mr: 5}}>
      <Typography  variant='h6'>Total</Typography>
      {usertype !== "Teacher" ?
      <Typography variant='h5'>₹ {subTotal + comboTotal}</Typography>
      :
      <Typography variant='h5'>₹ 0</Typography>
      }
      </Box>
      <Box sx={{textAlign:'center'}}>
      <VerifyOpen setFormdata={setFormdata} initialState={initialState} usertype={usertype} setUsertype={setUsertype} formdata={formdata} comboitems={comboitems} setComboItems={setComboItems} comboTotal={comboTotal} items={items} setItems={setItems} subTotal={subTotal} setLoader={setLoader}/>
      </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
      </Box>
    </div>
  );
}

export default Sales;