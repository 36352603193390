import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/InventoryTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getstudent } from '../../slices/student';
import { getproduct } from '../../slices/product';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Product Name', minWidth: 150, align:'start' },
  { id: 'current_stack', label: 'Stocks', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true}] },
];

const rows = [
  {sno: '01', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
  {sno: '02', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
];

const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(dataBlob, `${fileName}.xlsx`);
};

const InventoryScreen = ({setLoader}) => {

  const [filteredData, setFilteredData] = React.useState();

  const {products: studentData} = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const rows = studentData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  const excelData = studentData?.map((va, index)=>{
    return{'S.No': index+1,
      'Product  Name': va.name,
      'Current Stock': va.current_stack
    }
  })

  React.useEffect(()=>{
    setFilteredData(excelData);
  }, [studentData])

  const handleExport = () => {
    exportToExcel(filteredData, 'stock_data');
  };

  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
        <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Manage Stocks</Typography>
          </Grid>
          <Grid item xs={3} sx={{paddingTop: 0}}>
          <TextField
          size='small'
      variant="outlined"
      placeholder='Search'
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{fontSize: 26}}/>
          </InputAdornment>
        ),
      }}
    />
          </Grid>
          <Grid item xs={9} sx={{ paddingTop: 0, textAlign:'end'}}>
          <Button variant="contained" onClick={handleExport}>Export To Excel</Button>
          </Grid>
          {/* <Grid item xs={3} sx={{ paddingTop: 0}}>
            <Addstudent setLoader={setLoader} />
          </Grid> */}
          {/* <Grid item xs={3} sx={{paddingTop: 0}}>
            <Button fullWidth variant='contained'>Bulk Import</Button>
          </Grid>
          <Grid item xs={3} sx={{textAlign: 'end', paddingTop: 0}}>
          <Button fullWidth variant='contained'>Export</Button>
          </Grid> */}
          </Grid>
        </Box>
         <Box>
           <CustomTable setLoader={setLoader} columns={columns} rows={studentData ? rows : []} />
         </Box>
    </div>
  );
}

export default InventoryScreen;